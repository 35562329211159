import { fill, isNull, isUndefined } from "lodash";
import Signature from "../Signature";
import { TempObligationQuestionBody } from "./tempObligationQuestionBody";
// import { Template1 } from "./Template1";
import { TableView } from "./TableView";
import "./style.css";
import { Template3 } from "./Template3";
import DateObject from "react-date-object";
import arabic from "react-date-object/calendars/arabic";
import arabic_ar from "react-date-object/locales/arabic_en";
// import { StepTemplate } from "./StepTemplate";
import { TemplateObligation } from "./TemplateObligation";
import { StepTemplate } from "../obligation/stepTemplate/StepTemplate";
import { StepStats } from "../obligation/stepStats/StepStats";

const Templates = {
  Table: TableView,
  "Template 1": TemplateObligation,
  "Template 2": TempObligationQuestionBody,
  "Template 3": Template3,
  TemplateObligation: TemplateObligation,
  "Template 4": () => <h1>Template 4</h1>,
  "Template 5": () => <h1>Template 5</h1>,
};

export const getComponent = (
  field,
  renderForm,
  translation,
  ScoringResult,
  previousStep,
  allData,
  submissionInfo,
  getCurrentStepData
) => {
  let components = field.component || [];
  const mySteps = [];
  let comp = components[0];
  let props = components[1];

  if (props?.["pdf-disable"]) return null;

  let View = TableView;

  if (props?.["pdf-template"]) {
    View = Templates[props?.["pdf-template"]];
  }

  switch (comp) {
    case "Select":
    case "Input.TextArea":
    case "Input":
    // case "Radio":
    case "NumberPicker":
    case "Radio.Group":
    case "DatePicker":
    case "Rate":
    case "TimePicker":
      mySteps.push(
        <View
          field={field}
          props={props}
          comp={comp}
          translation={translation}
          allData={allData}
        />
      );

      // if (
      //   field.address.split(".").pop() === "site" &&
      //   submissionInfo?.site?.cluster_name
      // ) {
      //   mySteps.push(
      //     <View
      //       field={{
      //         value:
      //           translation[submissionInfo?.site?.cluster_name] ||
      //           submissionInfo?.site?.cluster_name,
      //         title: "التجمع",
      //       }}
      //       props={props}
      //       comp={comp}
      //       translation={translation}
      //       allData={allData}
      //     />
      //   );
      // }
      break;

    case "DatePickerHijri":
      mySteps.push(
        <View
          field={field}
          props={props}
          comp={comp}
          translation={translation}
          className="break-inside"
          allData={allData}
          compValue={(value) =>
            new DateObject({
              date: new Date(value),
              calendar: arabic,
              locale: arabic_ar,
            }).format?.("YYYY-MM-DD")
          }
        />
      );
      break;

    case "TreeSelect":
      mySteps.push(<h4>"TreeSelect"</h4>);
      break;
    case "Cascader":
      mySteps.push(<h4>"Cascader"</h4>);
      break;

    case "Field":
      mySteps.push(<h4>"Field"</h4>);
      break;
    // case "Radio":
    //   mySteps.push(<h4>"Radio"</h4>);
    //   break;
    // case "Radio.Group":
    //   mySteps.push(<h4>"Radio.Group": {field.title}</h4>);
    //   break;
    case "Checkbox":
      mySteps.push(<h4>"Checkbox"</h4>);
      break;
    case "Slider":
      mySteps.push(<h4>"Slider"</h4>);
      break;
    // case "Rate":
    //   mySteps.push(<h4>"Rate"</h4>);
    //   break;
    // case "NumberPicker":
    //   mySteps.push(<h4>"NumberPicker"</h4>);
    //   break;
    case "Transfer":
      mySteps.push(<h4>"Transfer"</h4>);
      break;
    case "Password":
      mySteps.push(<h4>"Password"</h4>);
      break;
    // case "DatePicker":
    //   mySteps.push(<h4>"DatePicker"</h4>);
    //   break;
    case "TimePicker":
      mySteps.push(<h4>"TimePicker"</h4>);
      break;
    case "Upload":
    case "Upload.Dragger":
      mySteps.push(
        <View
          allData={allData}
          compValue={(value) =>
            value?.length &&
            field.value
              ?.filter?.((file) => file?.response?.imageUrl)
              ?.map?.((file, i) => (
                <>
                  <br />
                  <a href={file?.response?.imageUrl} target="_blank">
                    {" "}
                    {i + 1} ) {file.name}
                  </a>
                </>
              ))
          }
          field={field}
          props={props}
          comp={comp}
          translation={translation}
        />
      );

      break;
    case "Switch":
      mySteps.push(<h4>"Switch"</h4>);
      break;
    case "Text":
      //   mySteps.push(<h4>"Text" {field.title}</h4>);
      break;
    case "Card":
      //   mySteps.push(<h4>"Card"</h4>);
      break;
    case "Space":
      mySteps.push(<h4>"Space"</h4>);
      break;
    case "Object":
      mySteps.push(<h4>"Object"</h4>);
      break;

    case "FormStep.StepPane":
      let currentStep = getCurrentStepData(field?.path)?.[0];

      mySteps.push(
        <>
          <div className="break-inside">
            <StepStats
              step={currentStep}
              translation={translation}
              title={props.title}
            />
          </div>
          {/* {previousStep.current === 1 && <>{ScoringResult}</>} */}
          <h4 style={{ color: "white" }}>.</h4>
          <StepTemplate
            title={props.title}
            allData={allData}
            translation={translation}
          />
        </>
      );
      previousStep.current++;

      break;
    case "ArrayTable":
      mySteps.push(
        <>
          <div
            style={{
              backgroundColor: "#F6F8FA",
              display: "flex",
              justifyContent: "center",
              height: 30,
              alignItems: "center",
              border: "1px solid #ebecf1",
              overflow: "hidden",
              marginTop: 10,
              borderTopRightRadius: 5,
              borderTopLeftRadius: 5,
            }}
          >
            {props?.["pdf-title"]}
          </div>
        </>
      );
      break;
    // case "FormCollapse":
    //   mySteps.push(<h4>"FormCollapse"</h4>);
    //   break;
    // case "FormGrid":
    //   mySteps.push(<h4>"FormGrid"</h4>);
    //   break;
    // case "FormLayout":
    //   mySteps.push(<h4>"FormLayout"</h4>);
    //   break;
    case "Signature":
      mySteps.push(
        <Signature
          disabled={true}
          {...field}
          title={props.title}
          translation={translation}
        />
      );
      break;
    case "Custom":
      mySteps.push(<h4>"Custom"</h4>);
      break;

    case "FormCollapse.CollapsePanel":
      mySteps.push(
        <>
          <div
            style={{
              backgroundColor: "#F6F8FA",
              display: "flex",
              justifyContent: "center",
              height: 30,
              alignItems: "center",
              border: "1px solid #ebecf1",
              overflow: "hidden",
              marginTop: 10,
              borderTopRightRadius: 5,
              borderTopLeftRadius: 5,
            }}
          >
            {props?.["header"]}
          </div>
        </>
      );

    case undefined:
      let f = field.address?.split?.(".");
      let index = f?.pop?.();

      if (!isUndefined(index)) {
        let p = renderForm.getFieldState(f);

        if (p && p.component && p.component[1].title) {
          let tit = field?.value?.title || p.component[1].title;
          if (!p.component[1].hide_card) {
            mySteps.push(
              <div
                className="break-inside"
                dir="rtl"
                style={{
                  backgroundColor: "#F6F8FA",
                  display: "flex",
                  justifyContent: "center",
                  height: 30,
                  alignItems: "center",
                  border: "1px solid #ebecf1",
                  overflow: "hidden",
                  marginTop: 10,
                  borderTopRightRadius: 5,
                  borderTopLeftRadius: 5,
                }}
              >
                {translation[tit] || tit} # {parseInt(index) + 1}
              </div>
            );
          }
        }
      }

    default:
      // console.log(comp, props);
      break;
  }

  return mySteps;
};
